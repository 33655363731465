import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import ContactLink from '../../micro-components/contact-link'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'La Musica degli Ultimi Medici',
    desc =
      `Riprese del concerto trasmesso in streaming dell'ensemble I Musici del Gran Principe.`,
    subtitle = 'Streaming',
    roles = ['Videomaker', 'Tecnico del Suono'],
    videoSrc = ['https://www.youtube.com/watch?v=oR116X9_NM0'],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      desc={desc}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc}/>
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-ultimi-medici.jpg'
        alt='Cover per il lavoro La Musica degli Ultimi Medici'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          L'evento è inserito nel programma ufficiale delle celebrazioni per la{' '}
          <em>Festa della Toscana 2020</em> ed è realizzato in compartecipazione con il
          Consiglio Regionale della Toscana.
        </span>
        <span>
          Concerto in streaming dell'ensemble{' '}
          <ContactLink
            to='https://musicidelgranprincipe.it'
            external={true}
            type='none'
          >
            I Musici del Gran Principe
          </ContactLink>{' '}
          diretto da <em>Samuele Lastrucci</em>.
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2'>
        <span>
          Mottetti di A. Melani, G. M. Casini, A. B. della Ciaja e G. C. M.
          Clari. Si ringraziano per la collaborazione il <em>Museo de' Medici</em> e la {' '}
          <em>Fondazione Anna Maria Luisa de' Medici</em>.
        </span>
      </p>
      {/* ADD HERE ALL THE PHOTOS */}
      <StaticImage
        src='../../images/photo/ultimi-medici-photo.png'
        alt='Foto Ultimi Medici'
        className='work-img work-img-contain video-1'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      {/* ADD HERE ALL THE LOGOS */}
      <div className='logo-container'>
      <StaticImage
        src='../../images/logo/logo-toscana.png'
        alt='Logo Regione Toscana'
        className='work-logo-bigger logo-1'
      />
      <StaticImage
        src='../../images/logo/logo-medici.png'
        alt='Logo Museo Medici'
        className='work-logo-big logo-3'
      />
      <StaticImage
        src='../../images/logo/logo-anna-maria.png'
        alt='Logo Fondazione Anna Maria'
        className='work-logo-bigger logo-2'
      />
      </div>
    </WorkLayout>
  )
}

export default Work
